import React from "react";
import Image from "next/image";

import { useAppContext } from "@/context/Context";

import separatorTop from "@/public/images/separator/separator-top.svg";
import separatorBottom from "@/public/images/separator/separator-bottom.svg";
import separatorTopLight from "@/public/images/light/separator/separator-top.svg";
import separatorBottomLight from "@/public/images/light/separator/separator-bottom.svg";

const Separator = ({ top, extraClass = "" }) => {
  const { isLightTheme } = useAppContext() || {};
  
  return (
    <>
      {top ? (
        <div className={`cre8tegpt-separator ${extraClass}`}>
          {isLightTheme ? (
            <Image
              className={"w-100 separator-dark"}
              src={separatorTop}
              alt="separator"
            />
          ) : (
            <Image
              className={"w-100 separator-light"}
              src={separatorTopLight}
              alt="separator"
            />
          )}
        </div>
      ) : (
        <div className="cre8tegpt-separator">
          {isLightTheme ? (
            <Image
              className={"w-100 separator-dark"}
              src={separatorBottom}
              alt="separator"
            />
          ) : (
            <Image
              className={"w-100 separator-light"}
              src={separatorBottomLight}
              alt="separator"
            />
          )}

        </div>
      )}
    </>
  );
};

export default Separator;
